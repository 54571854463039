<template>
  <div>
    <points-line class="hidden" :class="{ 'show' : getInfinityAnimaton }">
    </points-line>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PointsLine from '@/components/atom/loader/BigPointsLine.vue';

export default {
  components: {
    PointsLine,
  },
  props: ['options'],
  data: () => ({
    observer: null,
  }),
  computed: mapGetters('vkRequests', ['getInfinityAnimaton']),
  mounted() {
    const options = this.options || {};
    this.observer = new IntersectionObserver(([entry]) => {
      if (entry && entry.isIntersecting) {
        this.$emit('intersect');
      }
    }, options);

    this.observer.observe(this.$el);
  },
  unmounted() {
    this.observer.disconnect();
  },
};
</script>

<style lang="scss" scoped>
.hidden {
  opacity: 0;

  display: block;
  width: 100px;
  height: 50px;
}

.show {
  opacity: 1;
}
</style>
