<template>
  <div class="bar-content" ref="content">
    <filter-toogle :menu="filterMenu" class="bar-content__filter" @setActiveLink="setActiveLink" />

    <div v-if="showMenu" class="show-filter">
      <menu-card v-for="(card, index) in cards" :key="index" @resetField="resetField(index)">
        <template #title>
          <div class="bar-title">
            {{ card.title }}
          </div>
        </template>

        <template #input>
          <div class="bar-result">
            <input
              type="text"
              class="input"
              maxlength="17"
              placeholder="0"
              v-model="card.countFrom"
              @input="requestPageByParams(index)"
            />

            <p class="bar-result__text">-</p>

            <input
              type="text"
              class="input"
              placeholder="1000000000"
              maxlength="17"
              v-model="card.countTo"
              @input="requestPageByParams(index)"
            />
          </div>
        </template>
      </menu-card>

      <hr class="line" />

      <arrow-filter class="sort-filter"> </arrow-filter>

      <hr class="line" />

      <radio-filter
        :buttons="{ list: type, groupName: 'type' }"
        title="Тип"
        @updateItem="updateItem"
      ></radio-filter>

      <radio-filter
        v-if="type[2]?.checked"
        :buttons="{ list: status, groupName: 'status' }"
        title="Статус"
        @updateItem="updateItem"
      ></radio-filter>

      <hr class="line" />

      <check-box
        :items="checkButtons"
        class="filters__checkbox"
        @emitState="updateCheckBox"
      ></check-box>

      <hr class="line" />

      <div class="categories" v-if="showCatalogs">
        <div class="categories__catalogs">
          <div class="categories__panel">
            <p class="categories__title">Категории</p>

            <close-icon @click="reset"></close-icon>
          </div>

          <catalog-folder
            :ref="'folders' + index"
            v-for="(catalog, index) in getCategoryes"
            :key="index"
            :projects="catalog"
            @emmiter="setClass"
            @reset="resetCatalog"
          >
          </catalog-folder>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import MenuCard from '@/components/vkMenu/settings/PublicSettingCard.vue';
import RadioFilter from '@/components/common/filter/RadioButtonFilter.vue';
import CheckBox from '@/components/atom/button/check/CheckBox.vue';
import CatalogFolder from '@/components/common/catalogs/CatalogFolder.vue';
import FilterToogle from '@/components/common/filter/FilterTableToogle.vue';
import ArrowFilter from '@/components/public-sale/ArrowFilterSale.vue';

let callDebounce = null;

export default {
  components: {
    MenuCard,
    RadioFilter,
    CheckBox,
    CatalogFolder,
    FilterToogle,
    ArrowFilter,
  },
  emits: ['updateByFilter'],
  props: {
    currentAPI: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  computed: {
    ...mapGetters('vkValues', ['getPeriod', 'getType', 'getStatus', 'getSaleButtons']),
    ...mapGetters('vkRequests', ['getCategory', 'getGroupCount', 'getAdminsId']),
    ...mapGetters('publicSale', ['getCategoryes']),
  },

  data: () => ({
    showMenu: true,
    cards: [
      {
        title: 'Подписчики',
        countFrom: '',
        countTo: '',
        type: 'input',
      },
    ],
    period: [],
    type: [],
    status: [],
    checkButtons: [],
    showCatalogs: true,
    activeLink: null,
    filterMenu: ['Общие', 'Избранные', 'Мои группы'],
  }),

  methods: {
    ...mapActions('publicSale', [
      'updateGroupsByFilter',
      'requestGroups',
      'takeFavoriteGroups',
      'takeAdminGroups',
    ]),

    setActiveLink(value) {
      // console.log(this.filterMenu[0], value);
      if (this.filterMenu[0] === value) {
        this.showMenu = true;
        this.requestGroups();
        // this.$emit('updateByFilter');
        return;
      }

      this.showMenu = false;
      const fieldName = value === 'Мои группы' ? 'admins' : 'favorites';
      this.updateCardsByFilter(fieldName);
    },

    updateCardsByFilter(name) {
      const requestMethodName = name === 'favorites' ? 'takeFavoriteGroups' : 'takeAdminGroups';
      this[requestMethodName]();
      // this.updateVisibleAdminLink(requestMethodName === 'getFavoriteGroup');
    },

    resetField(index) {
      this.cards[index].countTo = '';
      this.cards[index].countFrom = '';

      callDebounce(index);
    },

    requestPageByParams(index) {
      const { countFrom, countTo } = this.cards[index];
      // console.log('countFrom', countFrom, countTo);
      if (+countTo > 10000000000000000) {
        this.cards[index].countTo = 10000000000000000;
      }

      if (+countFrom > 10000000000000000) {
        this.cards[index].countFrom = 10000000000000000;
      }

      if ((countFrom === '' && countTo !== '') || (countFrom !== '' && countTo === '')) {
        callDebounce(index);
        return;
      }
      this.cards[index].countFrom = Number(countFrom) ? countFrom : '';
      this.cards[index].countTo = Number(countTo) ? countTo : '';

      callDebounce(index);
    },

    requestByInput(index) {
      // this.cards[index]
      const newAuditory = {
        countFrom: this.cards[index].countFrom,
        countTo: this.cards[index].countTo,
      };

      // console.log('requestByInput', index);
      // this.currentAPI.updateByFilter(this.cards[index].type, newAuditory);
      this.updateGroupsByFilter({ groupName: this.cards[index].type, settingName: newAuditory });
    },

    debounce(f, t) {
      return (index) => {
        const previousCall = this.lastCall;
        this.lastCall = Date.now();

        if (previousCall && this.lastCall - previousCall <= t) {
          clearTimeout(this.lastCallTimer);
        }

        this.lastCallTimer = setTimeout(() => f(index), t);
      };
    },

    updateItem(currentId, groupName) {
      this[groupName].forEach((element, index) => {
        if (element.id === currentId) {
          this[groupName][index].checked = true;

          this.callRequest(groupName, element.settingName.toLowerCase());
          return;
        }
        this[groupName][index].checked = false;
      });
    },

    updateCheckBox(checkbox) {
      if (checkbox.name === 'verification') {
        // console.log('checkbox', checkbox);
        // this.currentAPI.updateByFilter(checkbox.name, checkbox.isChecked);
        this.updateGroupsByFilter({ groupName: checkbox.name, settingName: checkbox.isChecked });
        // this.$emit('updateByFilter');
      }
    },

    checkContentByCheckbox(checkbox) {
      let closePage = '';
      let favorites;
      let admins;

      if (checkbox.name === 'favorites') {
        closePage = 'admins';
      } else {
        closePage = 'favorites';
      }

      // this.currentAPI.updateByFilter('input', newAuditory);

      this.checkButtons.forEach((el, index) => {
        if (el.name === closePage) {
          this.checkButtons[index].isChecked = false;
        }

        if (closePage !== 'admins' && el.name === 'verification') {
          this.checkButtons[index].isChecked = false;
        }

        if (el.name === 'favorites') {
          favorites = el;
        }
        if (el.name === 'admins') {
          admins = el;
        }
      });

      if (!favorites.isChecked && !admins.isChecked) {
        this.requestGroups();
        return;
      }

      this.$emit('requestNewPage', checkbox.name);
    },

    callRequest(groupName, settingName) {
      if (groupName === 'type' && settingName === 'group') {
        this.showCatalogs = false;
      } else if (groupName === 'type') {
        this.showCatalogs = true;
      }

      this.updateGroupsByFilter({ groupName, settingName });
      // this.currentAPI.updateByFilter(groupName, settingName);
      // this.$emit('updateByFilter');
    },

    resetCatalog() {
      if (this.activeLink !== null) {
        this.activeLink.classList.remove('active');
        this.activeLink = null;

        this.updateGroupsByFilter({ groupName: 'catalog', settingName: '' });
        // this.currentAPI.updateByFilter('catalog', '');
        // this.$emit('updateByFilter');
      }
    },

    // set and reset active classes
    setClass(event, checkByeRef = false) {
      switch (true) {
        case checkByeRef:
          return;
        case this.activeLink === null:
          this.activeLink = event.target;
          this.activeLink.classList.add('active');
          break;
        case this.activeLink !== event.target:
          this.activeLink.classList.remove('active');
          this.activeLink = event.target;
          this.activeLink.classList.add('active');
          break;
        case this.activeLink === event.target:
          this.activeLink.classList.remove('active');
          this.activeLink = null;
          break;
        default:
      }

      const resultStr = this.activeLink === null ? '' : this.activeLink.innerText;
      // console.log('resultStr', resultStr);

      this.updateGroupsByFilter({ groupName: 'catalog', settingName: resultStr });
      // this.currentAPI.updateByFilter('catalog', resultStr);
      // this.$emit('updateByFilter');
    },

    reset() {
      this.getCategory.forEach((element, index) => {
        this.$refs[`folders${index}`].resetList();
      });
    },
  },
  mounted() {
    this.period = this.getPeriod;
    this.type = this.getType;
    this.status = this.getStatus;

    this.checkButtons = this.getSaleButtons;

    callDebounce = this.debounce(this.requestByInput, 1000);
  },
};
</script>

<style lang="scss" scoped>
.bar-content {
  width: 100%;

  &__filter {
    font-size: 18px;
    width: 100%;
    box-sizing: border-box;
  }
}
.bar-title {
  width: 100%;
  text-align: left;
  font-size: 18px;
  // padding: 10px 0 0 10px;
}

.slider {
  display: block;
  position: relative;

  height: 36px;
  width: 100%;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.slider-touch-left,
.slider-touch-right {
  display: block;

  position: absolute;

  height: 36px;
  width: 36px;

  padding: 6px;

  z-index: 2;
}

.slider-line {
  position: absolute;
  left: 18px;
  top: 16px;

  width: calc(100% - 36px);
  height: 10px;
  border-radius: 4px;

  z-index: 0;
  overflow: hidden;

  background: #d0d9e3;
  border: 1px solid #a2b3c6;
}

.span2 {
  display: block;

  height: 100%;
  width: 0%;

  border: 1px solid #a2b3c6 !important;
  background: #a2b3c6;
}

.span1 {
  display: block;

  width: 100%;
  height: 100%;

  border: 1px solid #a2b3c6 !important;
  background: #fff;
  border-radius: 50%;
}

.bar-result {
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 15px 0 0 0;

  &__text {
    margin: 0 15px;
    font-size: 18px;
  }
}
.icon-close {
  position: absolute;
  top: 10px;
  right: 10px;
}
.input {
  display: flex;

  width: 100%;
  height: 25px;

  margin: 0 10px;

  align-items: center;
  font-size: 16px;
  text-align: center;
  color: #000;

  &::placeholder {
    color: #8e8e8f;
  }

  &:focus {
    opacity: 1;
  }
}

@media (max-width: 1100px) {
  .input {
    margin: 0 3px;
  }
  .result {
    &__text {
      margin: 0 0 0 0;
    }
  }
}

.categories {
  width: 100%;

  &__title {
    font-weight: 700;
    font-size: 18px;
  }

  &__panel {
    display: flex;
    justify-content: space-between;
    align-items: center;

    position: relative;
    height: 30px;
  }
}

.line {
  display: block;
  width: 100%;
  content: "";
  height: 0;
  display: block;
  border-top: 1px solid #d8dfea;
  margin: 10px 0 10px 0;
}

.show-filter {
  width: 100%;
  height: auto;
}

.sort-filter {
  margin: 10px 0;
}
</style>
