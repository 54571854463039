<template>
  <div class="wrap">
    <div class="folder" @click="showCatalog(project, $event)">
      <img
        src="/icons/common/vector-arrow.svg"
        :class="{ folder__arrow: !listShow }"
        class="folder__icon"
      />

      <a href="#" ref="cat" class="folder__title" @click.prevent>
        {{ projects.cat }}
      </a>
    </div>

    <div v-if="listShow" class="folder__list">
      <div
        v-for="(project, index) in this.projects.sub_cat"
        :key="index"
        class="folder__project project"
      >
        <a
          href="#"
          class="project__link"
          :ref="'subCat' + index"
          @click.prevent="broadcastProject(project, $event)"
        >
          {{ project }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['emmiter', 'reset'],

  props: {
    projects: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      listShow: false,
      activeLink: null,
    };
  },

  methods: {
    // TODO remake name and replace emmite
    broadcastProject(project, event) {
      this.$emit('emmiter', event);
    },

    takeEvent(currentRef) {
      return this.$refs[currentRef];
    },

    showCatalog(project, event) {
      this.listShow = !this.listShow;
      if (this.listShow) {
        this.$emit('emmiter', event);
        return;
      }

      this.$emit('reset', event);
      // if (!this.listShow) this.$emit('reset');
    },

    resetList() {
      if (this.listShow) {
        this.$emit('reset');
      }

      this.listShow = false;
    },

    replaceString(str) {
      const re = /'/gi;
      return str.replace(re, '');
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.folder {
  display: flex;
  align-items: center;

  font-weight: normal;
  height: 25px;

  overflow: hidden;

  &__arrow {
    transform: rotate(-90deg);
  }

  &__icon {
    width: 10px;
    height: 10px;

    // background: #5e82a8;
  }

  &__title {
    display: block;
    position: relative;

    width: 100%;
    text-align: start;

    margin: 0 5px;

    font-size: 16px;
    font-weight: normal;
    line-height: 25px;
    height: 25px;

    color: #2b587a;
    text-decoration: none;
  }

  // .folder__list
  &__list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    position: relative;
    // margin: 15px 0 0 0;
  }

  &__project {
    width: 100%;
    // height: 25px;

    // padding: 0px 0 0px 15px;
    //margin: 5px 0;

    &:hover {
      background: #afacac3b;
    }
  }

  &:hover {
    background: #afacac3b;
  }
}

.project {
  display: flex;
  position: relative;

  // .project__link
  &__link {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    height: 100%;
    width: 100%;

    text-align: left;
    text-decoration: none;
    font-weight: normal;
    font-size: 16px;
    padding: 0px 0 0px 15px;
  }
}

.active {
  background-color: #5e82a8;
  color: #fff;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.6) inset;
  border-radius: 0;
}

@media (max-width: 1100px) {
  .folder {
    &__title {
      display: block;
      position: relative;

      width: 100%;
      text-align: start;

      margin: 0 5px;

      font-size: 16px;
      font-weight: normal;
      line-height: 25px;
      height: 25px;

      overflow: hidden;
      text-overflow: ellipsis;

      color: #2b587a;
      text-decoration: none;
    }
  }

  .project {
    display: flex;
    position: relative;

    // .project__link
    &__link {
      display: block;
      align-items: center;
      justify-content: flex-start;

      height: 100%;
      width: 100%;

      text-align: left;
      text-decoration: none;
      font-weight: normal;
      font-size: 16px;
      padding: 0px 0 0px 15px;

      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
