<template>
  <div class="setting-card">
    <div class="title-pannel">
      <slot name="title"></slot>
      <close-icon class="icon-close" @click="$emit('resetField')"></close-icon>
    </div>

    <div class="body-pannel">
      <slot name="input"></slot>
    </div>
  </div>
</template>

<script>
import CloseIcon from '@/components/atom/html-icon/symbol/CloseIcon.vue';

export default {
  components: {
    CloseIcon,
  },
  emits: ['resetField'],
};
</script>

<style lang="scss" scoped>
.setting-card {
    display: flex;
    flex-direction: column;

    width: 100%;
}
.title-pannel {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px 0 0 0;
    box-sizing: border-box;
}

</style>
