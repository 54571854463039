<template>
  <ScrollTop>
    <img src="/icons/common/vector-arrow.svg" class="img"/>
  </ScrollTop>
</template>

<script>
import ScrollTop from '@/components/atom/button/scroll/ScrollTopItem.vue';

export default {
  components: {
    ScrollTop,
  },
};
</script>

<style scoped>
.img{
  width: 100%;
  height: 100%;
  transform: rotate(180deg);
}
</style>
