<template>
  <a class="button-item" @click="$emit('click')">
    <slot></slot>
  </a>
</template>

<script>
export default {
  emits: ['click'],
};
</script>

<style lang="scss" scoped>
.button-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  cursor: default;
  border-radius: 10px;
  position: relative;

  text-decoration: none;

  // &:hover .panel__text {
  //   text-decoration: underline;
  // }
}
</style>
