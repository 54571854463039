<template>
  <div class="check-box">
    <label class="check-box__label" v-for="item in items" :key="item.id">
      <input
        type="checkbox"
        class="check-box__input"
        v-model="item.isChecked"
        @change="emitState(item)"
      />

      <span class="check-box__text"> {{ item.title }} </span>
    </label>
  </div>
</template>

<script>
export default {
  emits: ['emitState'],

  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  methods: {
    emitState(item) {
      this.$emit('emitState', item);
    },
  },
};
</script>

<style lang="scss" scoped>
.check-box {
  display: flex;
  flex-direction: column;

  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  align-content: flex-start;

  position: relative;

  &__input {
    display: none;

    &:checked + .check-box__text::after {
      opacity: 1;
    }
    &:checked + .check-box__text::before {
      border: 2.5px solid #5098ff;
    }
  }

  &__text {
    display: flex;

    position: relative;

    padding: 6px 0 6px 35px;

    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;

    // color: #c5c5c5;

    &::before {
      content: "";
      position: absolute;
      left: 10px;
      top: 7px;

      width: 15px;
      height: 15px;

      border: 2.5px solid #999999;
      box-sizing: border-box;
      border-radius: 4px;
    }

    &::after {
      position: absolute;
      left: 14px;
      top: 11px;

      content: "";

      width: 7.5px;
      height: 7.5px;

      background: #5098ff;
      opacity: 0;
    }

    &:hover {
      background-color: #edf1f5;
    }
  }

  &__label {
    width: 100%;
  }
}
</style>
