<template>
  <div class="toogle-menu">
    <template v-for="(item, index) in menu" :key="index">
      <div :ref="'button' + index" class="container">
        <span ref="active" v-if="index === 0" class="active"></span>

        <button-component
        :style="{ width: buttonWidth, color: (active === index) ? '#fff': ''}"
        class="toogle-menu__button"
        @click="animateBlob(item, index)"
      >
        <span>{{ item }}</span>
      </button-component>
      </div>
    </template>
  </div>
</template>

<script>
import ButtonComponent from '@/components/common/BaseButton.vue';
// :style="{'width': widther}"
export default {
  component: {
    ButtonComponent,
  },
  watch: {
    setElement(newValue) {
      this.setActiveLink(newValue);
    },
    deep: true,
  },
  props: {
    menu: {
      type: Array,
      required: true,
    },
    setElement: {
      type: String,
      default: '',
    },
    buttonWidth: {
      type: String,
      default: '100%',
    },
  },
  emits: ['setActiveLink'],

  expose: ['animateBlob'],

  data() {
    return {
      activeLink: null,
      active: 0,
    };
  },

  methods: {
    setActiveLink(value) {
      this.activeLink = value;
      this.$emit('setActiveLink', value);
    },

    // anamition
    animateBlob(item, index, duration = 0.2) {
      const currentElement = this.getNavigationElement(0);
      const targetElement = this.getNavigationElement(index);
      const distance = this.getDistanceBetweenElements(currentElement, targetElement);
      const widthActiveElement = this.getActiveElementWidth(index);

      const { style } = this.$refs.active;
      style.width = `${widthActiveElement}px`;

      style.setProperty('transition-duration', `${duration}s`);
      style.setProperty('transform', `translateX(${distance}px)`);

      this.active = index;
      this.$emit('setActiveLink', item);
    },
    getActiveElementWidth(index) {
      const elementWidth = this.getNavigationElement(index);

      const { width } = elementWidth.getBoundingClientRect();

      return width;
    },
    getNavigationElement(index) {
      return this.$refs[`button${index}`];
    },
    getPositionAtCenter(element) {
      const {
        top, left,
      } = element.getBoundingClientRect();

      return {
        x: left,
        y: top,
      };
    },
    getDistanceBetweenElements(a, b) {
      const aPosition = this.getPositionAtCenter(a);
      const bPosition = this.getPositionAtCenter(b);

      return Math.abs(aPosition.x - bPosition.x);
    },
  },
  mounted() {
    const firstElement = this.setElement ? this.setElement : this.menu[0];
    this.activeLink = firstElement;

    this.animateBlob(this.activeLink, 0, 0);
  },
};
</script>

<style lang="scss" scoped>
.toogle-menu {
 display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 50px;
    /* border: 1px solid #3e4751; */
    border-radius: 7px;

  // .toogle-menu__button
  &__button {
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;

    height: 26px;
    padding: 0 10px;

    border-radius: 7px;

    box-sizing: border-box;
    flex-grow: 1;
    white-space: nowrap;

    color: #0f0f0f;
  }
}

.active {
  display: block;
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

  background: #5e82a8;
  border-radius: 7px;

  color: #ebe8e8;

  transition: transform 0.2s cubic-bezier(0.51, 0.06, 0.56, 1.37);
}

.container{
  position: relative;
}
</style>
