<template>
  <div class="arrow-block">
    <p class="arrow-block__title">Сортировать по:</p>

    <arrow-filter
      :ref="
        (el) => {
          divs[index] = el;
        }
      "
      v-for="(filter, index) in sort"
      :key="index"
      class=""
      @arrowAction="checkArrows(filter, index, $event)"
    >
      <template #label>
        {{ filter.name }}
      </template>
    </arrow-filter>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import ArrowFilter from '@/components/common/filter/ArrowFilter.vue';

// const activeArrow = ref('sub');
const divs = ref([]);
const store = useStore();
const activeIndex = ref(0);

const sort = ref([
  {
    filter: 'sub',
    name: 'подписчики',
    rev: false,
  },
  {
    filter: 'cov',
    name: 'охват',
    rev: false,
  },
  {
    filter: 'vis',
    name: 'посетители',
    rev: false,
  },
  {
    filter: 'grow',
    name: 'прирост',
    rev: false,
  },
]);

const checkByActiveIndex = (currentIndex) => Number(activeIndex.value) === Number(currentIndex);

const checkArrows = (filter, index, arrowState) => {
  const state = arrowState !== 2 ? '' : 't';
  // store.dispatch('publicSale/updateGroupsBySort', { filtPar: filter.filter, sortBy: state });
  if (!checkByActiveIndex(index)) {
    divs.value[activeIndex.value].setCheckedState(false, 0);
    activeIndex.value = index;
  } else if (checkByActiveIndex(index) && +arrowState === 2) {
    divs.value[activeIndex.value].setCheckedState(true, 0);
  }

  store.dispatch('publicSale/updateGroupsBySort', { filtPar: filter.filter, sortBy: state });
  // sort.value.forEach((element) => {
  // });
};

onMounted(() => {
  const filter = store.getters['publicSale/getSort'];
  const index = sort.value.findIndex((element) => element.filter === filter.filtredParametr);

  if (index !== -1) {
    const countState = filter.sortBy !== 't' ? 1 : 2;
    divs.value[index].setCheckedState(true, countState);
    activeIndex.value = index;
  }
});
</script>

<style lang="scss" scoped>
.arrow-block {
  width: 100%;
  height: auto;

  &__title {
    text-align: start;
    font-size: 18px;
  }
}
</style>
