<template>
  <div class="filter-arrow" @click.prevent="updateCheckBox">
    <p class="filter-arrow__text">
      <slot name="label"></slot>
    </p>

    <label class="filter-arrow__label" >
      <input ref="checkbox" type="checkbox" name="" class="filter-arrow__checkbox" @click.stop />

      <img ref="image" class="filter-arrow__icon" src="/icons/common/vector-arrow.svg" />
    </label>
  </div>
</template>

<script setup>
import { ref, defineExpose, defineEmits } from 'vue';

const emit = defineEmits(['arrowAction']);
const image = ref(null);

const checkbox = ref(null);
const count = ref(0);

const setCheckedState = (isChecked, countState) => {
  checkbox.value.checked = isChecked;
  count.value = countState;

  if (Number(countState) === 2) {
    image.value.style.transform = 'rotate(180deg)';
  }
  if (Number(countState) === 0) {
    image.value.style.transform = 'rotate(0deg)';
  }
};
defineExpose({ setCheckedState, count });

const updateCheckBox = () => {
  switch (count.value) {
    case 0:
      checkbox.value.click();
      break;
    case 1:
      image.value.style.transform = 'rotate(180deg)';
      break;
    default:
      checkbox.value.click();
      image.value.style.transform = 'rotate(0deg)';
  }

  emit('arrowAction', count.value);

  count.value += 1;
  if (count.value >= 3) {
    count.value = 0;
  }
};
</script>

<style lang="scss" scoped>
.filter-arrow {
  display: flex;
  align-items: flex-start;

  position: relative;

  margin: 10px 0 0 0;
  width: 100%;

  &__label {
    position: relative;
    width: 20px;
    height: 20px;

    // background: rgb(0, 0, 0);
  }

  &__checkbox {
    display: none;

    &:checked + .filter-arrow__icon {
      display: block;
    }
  }

  &__icon {
    position: absolute;
    top: 0;
    left: 0;

    width: 20px;
    height: 20px;

    display: none;
    user-select: none;
  }
  &__text {
    user-select: none;
    cursor: pointer;

    margin: 0 5px 0 0;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
  }
}
</style>
