<template>
  <div class="scroll" @click="scrollToTop" v-show="visible">
    <a class="scroll__button" @click.prevent>
      <slot></slot>
    </a>

    <div class="scroll__background"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    scrollTop() {
      document.getElementById('header').scrollTo(0, 1000);
    },

    scrollToTop() {
      // Scroll to top logic
      const rootElement = document.getElementById('container');
      rootElement.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },

    scrollListener() {
      this.visible = document.getElementById('header').getBoundingClientRect().top < 0;
    },
  },

  mounted() {
    const element = document.getElementById('container');
    element.addEventListener('scroll', this.scrollListener);
  },
  beforeUnmount() {
    const element = document.getElementById('container');
    element.removeEventListener('scroll', this.scrollListener);
  },
};
</script>

<style lang="scss" scoped>
.scroll {
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 8px;

  width: 40px;
  height: 40px;

  cursor: pointer;

  &__button {
    position: absolute;
    top: 0px;
    left: 3px;

    width: 35px;
    height: 35px;
  }

  &__background {
    background: #0000005e;

    width: 100%;
    height: 100%;
  }
}
</style>
